(function () {
  'use strict';

  /**
   * @ngdoc directive
   * @name home.directive:neoActionButton
   * @restrict EA
   * @element
   *
   * @description
   *
   * @example
     <example module="home">
       <file name="index.html">
        <neo-action-button></neo-action-button>
       </file>
     </example>
   *
   */
  angular
    .module('neo')
    .directive('neoActionButton', neoActionButton);

  function neoActionButton() {
    return {
      restrict: 'E',
      scope: {
        buttons: '='
      },
      templateUrl: 'directives/neo-action-button/neo-action-button-directive.tpl.html',
      replace: false,
      controllerAs: 'vm',
      controller: function ($scope, $state) {
        var vm = this;
        vm.name = 'neoActionButton';
        vm.buttons = $scope.buttons;

        vm.goToNewState = function (action) {
          if (!_.isUndefined(action.paramsToChange)) {
            var params = {};
            _.forEach(action.paramsToChange, function (value, key) {
              params[key] = _.isFunction(value) ? value() : value;
            });
            $state.go(action.stateToChange, params);
          } else {
            $state.go(action.stateToChange);
          }
        }
      },
      link: function (scope, element, attrs) {
        /* jshint unused:false */
        /* eslint "no-unused-vars": [2, {"args": "none"}] */
      }
    };
  }
}());
